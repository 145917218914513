import React from "react"

import Layout from "../components/layout"

import Navigation from "../components/shared/navigation/navigation";
import Header from "../components/index/header/header";
import Cases from "../components/index/cases/cases";

const IndexPage = () => (
  <Layout canNavigateBack = { false }>
    <Header></Header>
    <Cases></Cases>
  </Layout>
)

export default IndexPage
