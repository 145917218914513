import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import { ScrollIO } from '@imjasonmiller/scroll-io';
import { css } from "@emotion/core"
import Img from "gatsby-image"

import { border, borderLeft, borderRight, hero, caseCardWrapper, caseCard, caseMeta, heroCaseMeta, caseImage, coverImage, otherWork, listItem, listImage, listContent, listChevron, otherWorkTitle, imageOverlay, secondCaseCard, caseCardCenter } from "./cases.styles"

const Cases = () => {
    const refs = []
    
    useEffect(() => {
        const callback = ({ enterDown, leaveUp }, entry) => {
            const ratio = entry.intersectionRatio;

            if (entry.target.classList.contains("hero") && enterDown || entry.target.classList.contains("hero") && leaveUp) {
                document.querySelector(".borderLeft").style.transform = `translateX(${-100 * ratio}%)`;
                document.querySelector(".borderRight").style.transform = `translateX(${100 * ratio}%)`;

                if (entry.intersectionRatio > 0.8) {
                    document.querySelector(".heroCaseMeta").style.opacity = `${ 5 * (ratio - 0.8) }`;
                }
            }

            if (entry.target.classList.contains("card--case")) {
                if (entry.intersectionRatio > 0.5 && enterDown) {
                    entry.target.children[0].children[0].children[1].style.transform = `scale(1.1)`;
                    entry.target.children[0].children[0].children[0].style.transform = `translateY(-100%)`;
                }
            }

            if (entry.target.classList.contains("list--case")) {
                if (entry.intersectionRatio > 0.6 && enterDown) {
                    entry.target.style.transform = `translateY(0)`;
                    entry.target.style.opacity = 1;
                }
            }
        };
        
        const observer = new ScrollIO(refs, callback, {
            range: { steps: 100 },
        });

        return () => observer.disconnect();
    })

    const posts = useStaticQuery(graphql`
        query {
            allWordpressPost(filter: {sticky: {eq: false}}) {
                edges {
                    node {
                        id
                        title
                        slug
                        categories {
                            name
                        }
                        featured_media {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 2000, quality: 80) {
                                      ...GatsbyImageSharpFluid_withWebp_noBase64
                                      ...GatsbyImageSharpFluidLimitPresentationSize
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `).allWordpressPost

    

    const caseItem = () => {
        var gridRow = [4, 4, 5];
        var gridColumn = ["2 / span 5", "7 / span 5", "2 / span 10"]

        let caseCards = posts.edges.map((post, index) => {
            console.log(post.node.featured_media.localFile.childImageSharp.fluid)

            if (index > 0 && index <= 3) {
                return (
                    <div 
                        className = { "card--case" }
                        css = { [caseCardWrapper(gridColumn[index - 1], gridRow[index - 1]), index === 2 ? secondCaseCard : css``, index === 3 ? caseCardCenter : css``] }
                        ref = { (caseCardRef) => { refs.push(caseCardRef) } }
                        key = { index }
                    >
                        <a href= { post.node.slug }>
                            <div css = { [ caseCard ] } >
                                <div className = { "card--overlay" } css = { theme => [imageOverlay(theme)] } ></div>
                                <Img className = { "card--image" } css = { caseImage } imgStyle={{ objectFit: 'cover' }} fluid = { post.node.featured_media.localFile.childImageSharp.fluid } />
                            </div>
                            <div css = { [caseMeta] }>
                                <p><b>{ post.node.categories[0].name }</b></p>
                                <small>{ post.node.title }</small>
                            </div>
                        </a>
                    </div>
                )
            }
        })

        return (
            <>
                { caseCards }
            </>
        )
    }

    const list = () => {
        let listItems = posts.edges.map((post, index) => {
            if (index > 3) {
                return (
                    <a key = { index } href= { post.node.slug }>
                        <li 
                            css = { listItem } 
                            className = { "list--case" }
                            ref = { (caseCardRef) => { refs.push(caseCardRef) } }
                        >
                            <div css = { listImage } >
                                <Img fluid = { post.node.featured_media.localFile.childImageSharp.fluid } />
                            </div>
                            <div css = { listContent }>
                                <p><b>{ post.node.categories[0].name }</b></p>
                                <p>{ post.node.title }</p>
                            </div>
                            <div className = { "listChevron" } css = { listChevron }>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 6V18" css={ theme => `stroke: ${theme.colors.secondary}` } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <circle cx="12" cy="12" r="11.25" css={ theme => `stroke: ${theme.colors.secondary}` } strokeWidth="1.5"/>
                                    <path d="M18 12H6" css={ theme => `stroke: ${theme.colors.secondary}` } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </li>
                    </a>
                )
            }
        })

        return (
            <>
                <h2 
                    className = { "list--case" }
                    css = { otherWorkTitle }
                    ref = { (listTitle) => { refs.push(listTitle) } }
                >
                    Overig werk
                </h2>
                <ul>
                { listItems }
                </ul>
            </>
        )
    }

    return (
        <>
            <div
                className = { "hero" }
                ref = { (heroRef) => { refs.push(heroRef) } } 
                css = { hero }
            >
                <a href= { posts.edges[0].node.slug }>
                    <Img css = { coverImage } fluid = { posts.edges[0].node.featured_media.localFile.childImageSharp.fluid } />

                    <div className = { "borderLeft" } css = { theme => [border(theme), borderLeft]  }></div>
                    <div className = { "borderRight" } css = { theme => [border(theme), borderRight] }></div>

                    <div  className = { "heroCaseMeta" } css = { [ heroCaseMeta ] }>
                        <div>
                            <p><b>{ posts.edges[0].node.title }</b></p>
                            <small>{ posts.edges[0].node.categories[0].name }</small>
                        </div>
                    </div>
                </a>
            </div>

            { caseItem() }

            <div css = { otherWork }>
                { list() }
            </div>
        </>
    )
}

export default Cases