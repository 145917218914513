import { css } from "@emotion/core"

export const header = (theme) => css`
  grid-column: 3 / span 6;
  grid-row: 2;
  padding: 3.5em 0 2.5em;

  @media (max-width: ${ theme.breakpoint.mobile }) {
    grid-column: 1 / -1;
  }
`;

export const introText = css`
  font-size: 1.375em;
  line-height: 1.4;
  margin-top: 1.5em;
  margin-bottom: 2em;
`;