import React from "react";

import { header, introText } from "./header.styles";
import { useStaticQuery, graphql } from "gatsby";

const Header = () => {
  const aboutPost = useStaticQuery(graphql`
    query {
      allWordpressPost(filter: {sticky: {eq: true}}) {
        edges {
          node {
            id
            title
            content
          }
        }
      }    
    }
  `).allWordpressPost.edges[0].node

  return (
    <header css={ (theme) => header(theme) }>
      <p className="intro">Hi, I'm</p>
      <h1>{ aboutPost.title }</h1>
      <div 
        css={ introText } 
        dangerouslySetInnerHTML={ { __html: aboutPost.content } } 
      />
    </header>
  )
}

export default Header
