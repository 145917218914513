import { css } from "@emotion/core"

export const hero = css`
  grid-column: 1 / -1;
  grid-row: 3;
  height: 80vh;
  width: 100vw;
  margin-left: calc(-1 * (100vw - 90vw) / 2);
  background-color: #E9E9E9;
  position: relative;
  overflow: hidden;
`;

export const border = (theme) => css`
  background-color: ${ theme.colors.primary };
  width: calc((100vw - 90vw) / 2);
  height: 100vh;
  position: absolute;
  will-change: transform;
`;

export const borderLeft = css`
  left: 0;
  bottom: 0;
`;

export const borderRight = css`
  right: 0;
  bottom: 0;
`;

export const caseCardWrapper = (gridColumn, row) => css`
  grid-row: ${row};  
  grid-column: ${gridColumn};

  &:hover .card--image {
    transform: scale(1.0) !important;
  }

  @media (max-width: 560px) {
    grid-column: 1 / -1;
  }
`;

export const caseCard = css`
  height: 720px;
  overflow: hidden;
  position: relative;

  @media (max-width: 560px) {
    height: 560px;
  }
`;

export const secondCaseCard = css`
  margin-top: 15em;

  @media (max-width: 560px) {
    margin-top: 2em;
    grid-row: 5;
  }
`

export const caseCardCenter = css`
  @media (max-width: 560px) {
    grid-row: 6;
  }
`;

export const caseMeta = css`
  margin-top: 1em;
`;

export const heroCaseMeta = css`
  display: grid;
  grid-template-columns: repeat(12,1fr);
  grid-gap: 2em;
  margin: 0px auto;
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  opacity: 0;

  div {
    grid-column: 2 / span 4;
    grid-row: 1;
    align-self: end;
    margin-bottom: 30%;
  }
`;

export const coverImage = css`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const caseImage = css`
  height: 100%;
  
  transform: scale(1.3);
  will-change: transform;
  transition: transform 800ms cubic-bezier(.215,.61,.355,1);
`;

export const otherWork = (theme) => css`
  grid-row: 6;
  grid-column: 2 / span 10;
  margin-bottom: 8em;

  @media (max-width: ${ theme.breakpoint.mobile }) {
    grid-column: 1 / -1;
    grid-row: 7;
    margin-top: 4em;
  }
`;

export const otherWorkTitle = css`
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);

  opacity: 0;
  transform: translateY(64px);
  will-change: opacity, transform;
  transition: opacity, transform 1200ms cubic-bezier(.215,.61,.355,1); 
`;

export const listItem = css`
  padding: 1.5em 0;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
  display: flex;
  align-items: center;

  opacity: 0;
  transform: translateY(64px);
  will-change: opacity, transform;
  transition: opacity, transform 1200ms cubic-bezier(.215,.61,.355,1); 

  &:hover .listChevron {
    opacity: 1;
    transition: opacity 400ms cubic-bezier(.215,.61,.355,1);
    will-change: opacity;
  }
`;

export const listImage = css`
  height: 120px;
  width: 160px;
  overflow: hidden;
  border-radius: 6px;
`;

export const listContent = css`
  margin-left: 1em;
  margin-right: auto;

  p:first-of-type {
    margin-bottom: 0.25em;
  }
`;

export const listChevron = css`
  opacity: 0;
`

export const contact = css`
  height: 100vh;
  grid-row: 7;
  grid-column: 1 / -1;
`


export const imageOverlay = (theme) => css`
  position: absolute;
  background-color: ${ theme.colors.primary };
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  will-change: transform;
  transition: transform 1000ms cubic-bezier(.215,.61,.355,1);
`;